import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const RecaptchaWrapper = ({children}) => {
  return (
    <GoogleReCaptchaProvider scriptProps={{async: true, defer: true, }} reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
        {children}
    </GoogleReCaptchaProvider>
  )
}

export default RecaptchaWrapper