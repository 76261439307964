import React, {useState} from "react";
import logo from "./../assets/logo-black.png";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import cognismilesLogo from './../assets/cognismiles-logo.png'
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("")
  const [showConfirmPassword, setShowConfirmPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { token } = useParams();

  console.log(token)
  const navigate = useNavigate();

  const {executeRecaptcha} = useGoogleReCaptcha()

  const googleCaptcha = async () => {
         try {
             const token = await executeRecaptcha()
           // If token is available, proceed with form submission
           if (token) {
             // Send form data with token to your server
             console.log('TOKEN:', token)
             const response = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/verifyRecaptcha`, {token})
             console.log(response,'success captcha')
             return true;
           } else {
             console.log('TOKEN-Error', token)
             console.log('Ref')
             return false;
           }
         } catch (error) {
           console.log('Something went wrong:', error)
           return false;
         }
 }

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
    setError('*Passwords do not match!');
    return;
    }

    const validation = await googleCaptcha();
    if (!validation) {
        setError("Oops! something went wrong, please try again")
        return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}api/v1/reset-password?token=${token}`, {password}, {withCredentials: true})
    // axios.post(`http://localhost:3000/reset-password/${token}`, {password}, {withCredentials: true})
    .then((response) => {
        console.log("success", response)
        if(response.data.result.status === 200){
            setError("")
            toast.success("Password has been reset successfully.")

            navigate("/login");
        }
    })
    .catch((error) => {
        console.log('error', error);
        setError(error);
    })
  }
  return (
    <section class="bg-gray-50  font-pop">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md  sm:p-8">
        <img className="w-[100px] h-auto mx-auto" src={cognismilesLogo} alt="logo" />
                          <h1 className="mt-3 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                          Reset Password
                        </h1>
                        <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5">
                          {/* <div>
                                <label htmlFor="email" class="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                                <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="name@company.com" required />
                            </div> */}
                          <div>
                            <label
                              htmlFor="password"
                              className="block mb-2 font-medium text-primary "
                            >
                              New Password
                            </label>
                            <div className="relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={password}
                           
                              onChange={(e) => setPassword(e.target.value.replace(" ",''))}
                              placeholder="••••••••"
                              className="outline-none shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                              required
                            />
                             {showPassword ? 
                            <svg onClick={() => setShowPassword(!showPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                              <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg> : 
                            <svg onClick={() => setShowPassword(!showPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                            }
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="confirmPassword"
                              className="block mb-2 font-medium text-primary "
                            >
                              Confirm password
                            </label>
                            <div className="relative">
                            <input
                              type={showConfirmPassword ? "text" : "password" }
                              name="confirmPassword"
                              value={confirmPassword}
                  
                              onChange={(e) => setConfirmPassword(e.target.value.replace(" ", ''))}
                              placeholder="••••••••"
                              className="outline-none shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                              required
                            />
                            {showConfirmPassword ? 
                            <svg onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                              <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg> : 
                            <svg onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                        }
                        </div>
                          </div>
                          {error &&
                          <p className="text-red-500 text-sm font-medium">{error}</p>
                          }
                          <button
                           onClick={handleSubmit}
                           className="w-full text-white bg-secondary focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                          >
                            Update
                          </button>
                        </form>
        </div>
      </div>
    </section>
  );
}


export default ResetPassword;
